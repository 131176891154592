import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import "./style.scss"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiBrandPage {
        nodes {
          Title
          Slug
        }
      }
    }
  `);

  return (
    <footer className="text-light bg-dark px-4 py-2 mt-1">
      <ul>
        {data.allStrapiBrandPage.nodes.map(node => {
          return (
            <li key={node.Slug}>
              <Link className="text-light text-small" to={`/brands/${node.Slug}`}>
                {node.Title}
              </Link>
            </li>
          )
        })}
      </ul>
      © {new Date().getFullYear()}, Built by
      {` `}
      <a href="https://nich-o-las.github.io">Nick Cox</a>
    </footer>
  );
};
