/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
// import { graphql, useStaticQuery, Link } from "gatsby"
import { Container } from "reactstrap";
import Header from "../Header"
import Contact from "../Contact";
import Footer from "../Footer";

import "./style.scss"


const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <Container fluid="md" className="px-0">
        <Header />
      </Container>
      <Container fluid='md' className="ml-auto mr-auto px-0">
        {children}
        <Contact />
        <Footer />
      </Container>
    </div>
  )
}

export default Layout;


