import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { 
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
 } from "reactstrap"

import "./style.scss"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query categoryQuery {
      allStrapiCategory {
        nodes {
          Category
          Slug
        }
      }
    }
  `);
  const allCategories = data.allStrapiCategory.nodes;

  return (
    <>
      <Link to="/">
        <h4 className="Navbar-logo">
          CHOICE AUTOMOTIVE EQUIPMENT
          <small className="text-muted">{' '}LLC</small>
        </h4>
      </Link>
      <div>
        <Navbar color="primary" dark expand="md" className="shadow-lg mb-1">
          <NavbarBrand 
            href="/"
            className="Navbar-secondary-logo text-center text-wrap"
          > 
            CHOICE AUTOMOTIVE EQUIPMENT,
            <small className="text-muted">{' '}LLC</small>
          </NavbarBrand>
          <NavbarBrand 
            href="/"
            className="Navbar-third-logo text-center text-wrap"
          > 
            <small>CHOICE AUTOMOTIVE <br/> EQUIPMENT,</small>
            <small className="text-muted">{' '}LLC</small>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto ml-auto" navbar>
              <NavItem>
                <NavLink href="/">ABOUT</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#contact">
                  CONTACT
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  PRODUCTS
                </DropdownToggle>
                <DropdownMenu right>
                  {
                    allCategories.map(item => (
                        <DropdownItem key={`Dropdown${item.Slug}`}>
                          <Link to={`/categories/${item.Slug}`}>
                            {item.Category}
                          </Link>
                        </DropdownItem>
                      )
                    )
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
