import React from "react"
import { Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMap, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import "./style.scss"

export default function Contact() {
  return (
    <div className="Contact mt-1 bg-light p-4" id="contact">
      <h3 className="text-center">Contact Us</h3>
      <Row>
        <Col md="4" className="text-center">
          <a href="tel:615-274-2233">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              (615) 274-2233
          </a>
        </Col>
        <Col md="4" className="text-center">
          <a href = "mailto: sherlockcox@aol.com">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2"/>
            sherlockcox@aol.com
          </a>
        </Col>
        <Col md="4" className="text-center">
          <a href="https://maps.google.com?q=4824+US-41A%2C+Eagleville%2C+TN+37060">
              <FontAwesomeIcon icon={faMap} className="mr-2" />
              4824 US-41A, Eagleville, TN 37060
          </a>
        </Col>
      </Row>
    </div>
  )
}
